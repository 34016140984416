<template>

    <span>
    <v-layout mb-4>
      <v-flex xs10 ml-3>
        <h2 class="lime--text lighten-1 pt-3">Gerenciar Banner - Inclusão</h2>
      </v-flex>
      <v-flex xs2 mr-3 text-right>
        <v-btn
          color="blue-grey"
          class="black--text"
          :to="{name: 'banners'}"
        >
          <v-icon dark>keyboard_arrow_left</v-icon>
          Voltar
        </v-btn>
      </v-flex>
    </v-layout>
        <form-banner :btnLabel="btnLabel"></form-banner>
    </span>
        
</template>

<script>
import FormBannerComponent from "./partial/FormBannerComponent";

export default {
  name: "AddBannerComponent",
  created() {
    
  },
  data () {
    return {
      //id_setor: this.id,
      btnLabel: 'Incluir'
    }
  },
  methods: {
    
  },
  components: {
    formBanner: FormBannerComponent,
  }
};
</script>

<style scoped>

</style>
