<template>
<span>

    <v-form ref="form" v-model="valid" lazy-validation autocomplete="off">
        <v-container fluid>

            <v-row>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-select v-model="banner.banner_sector_id" :items="loadBannerSectors" item-text="bnnr_sec_nome" item-value="bnnr_sec_id" label="Selecione um Setor..." outlined></v-select>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field v-model="banner.bnnr_nome" :rules="rules.nomeRules" label="Nome" outlined required></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4">
                    <v-text-field v-model="banner.bnnr_w" label="Largura (px)" outlined></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4">
                    <v-text-field v-model="banner.bnnr_h" label="Altura (px)" outlined></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4">
                    <v-select v-model="banner.bnnr_tipo" :items="tipos" label="Tipo" outlined></v-select>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
                    <v-select v-model="banner.bnnr_lado" :items="lados" label="Lado" outlined></v-select>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
                    <v-text-field v-model="banner.bnnr_ordem" label="Ordem" outlined></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
                    <v-text-field v-model="banner.bnnr_link" label="Link" outlined></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
                    <v-select v-model="banner.bnnr_link_target" :items="targets" label="Target" outlined></v-select>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-switch color="lime" v-model="banner.bnnr_figurar" label="Figurar"></v-switch>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="text-right">
                    <v-btn class="btn-salvar" :disabled="!valid" color="success" @click="onSubmit">
                        <v-icon dark class="mdi mdi-check"></v-icon>
                        {{btnLabel}}
                    </v-btn>
                </v-col>
            </v-row>


        </v-container>
    </v-form>

</span>
</template>

<script>
import {
    URL_BASE
} from '../../../../config/configs'

export default {
    created() {
        this.$store.dispatch('loadBannerSectors');
    },
    computed: {
        loadBannerSectors() {
            console.log(this.$store.state.bannersectors.items)
            return this.$store.state.bannersectors.items;
        }
    },
    props: {
        banner: {
            require: false,
            type: Object | Array,
            default: () => {
                return {
                    bnnr_id: '',
                    banner_sector_id: '',
                    bnnr_nome: '',
                    bnnr_w: '',
                    bnnr_h: '',
                    bnnr_tipo: 'WEBP',
                    bnnr_lado: 'C',
                    bnnr_ordem: '0',
                    bnnr_setor: '',
                    bnnr_figurar: false,
                    bnnr_valor: 0.00,
                    bnnr_arquivo: '',
                    bnnr_html: '',
                    bnnr_link: '#',
                    bnnr_link_target: '_blank',
                    bnnr_clicks: '1',
                }
            },
        },
        id: '',
        update: {
            require: false,
            type: Boolean,
            default: false
        },
        btnLabel: {
            require: false,
            type: String,
            default: 'Salvar'
        },
        require: true,
    },
    name: "FormBannerComponent",
    data: () => ({
        tipos: ['JPG', 'PNG', 'WEBP', 'VIDEO'],
        lados: ['D', 'E', 'C', 'T'],
        targets: ['_blank', '_parent', '_self', '_top'],
        valid: true,
        rules: {
            nomeRules: [
                v => !!v || 'Campo Nome é obrigatório',
                v => (v && v.length <= 200) || 'Nome precisa ter ate 200 Caracteres'
            ],
        },
        base_url: URL_BASE
    }),
    methods: {

        onSubmit() {

            if (this.$refs.form.validate()) {
                const action = this.update ? 'updateBanner' : 'storeBanner';
                const msg = this.update ? 'Dados atualizados com sucesso' : 'Cadastro efetuado com sucesso';

                this.$store.dispatch(action, this.banner)
                    .then(() => {
                        this.$swal({
                            position: "center",
                            icon: "success",
                            title: msg,
                            showConfirmButton: true,
                            timer: 6000
                        });
                        this.$router.push({
                            name: 'banners'
                        });
                    })
                    .catch(error => {
                        this.$swal({
                            position: "center",
                            icon: "error",
                            title: 'Erro',
                            text: 'Algo deu errado',
                            showConfirmButton: true,
                            timer: 6000
                        });
                        this.errors = error.response.data.errors
                    })
            } else {
                this.$swal({
                    position: "center",
                    icon: "warning",
                    title: 'Alguns campos requer sua atenção',
                    text: 'Verifique os campos em vermelho',
                    showConfirmButton: true,
                    timer: 6000
                });
            }
        },
    }
};
</script>

<style scoped>
.theme--dark.v-sheet {
    background-color: #303030;
    border-color: #303030;
    color: #fff;
    box-shadow: none;
}

.btn-salvar {
    margin: 0;
}
</style>
